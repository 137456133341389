<template>
  <div class="documents-list">
    <div class="documents-container">
      <ul class="documents-list-content">
        <li
          v-for="(item, index) in documents"
          :key="item.url"
          class="documents__item"
          :class="index > 4 ? 'up-dropdown-content' : ''"
        >
          <div class="documents__item--link">
            <a :title="item.name" @click="getDocumentScr(item)">
              <i class="pi pi-file-pdf"></i>
              {{ item.name }}
            </a>
          </div>
          <DocumentDropdown
            :document="item"
            :tokenInfo="tokenInfo"
            :isCargoCare="isCargoCare"
            @shareDocument="(item) => $emit('shareDocument', item)"
          />
        </li>
        <div
          class="all-documents-container"
          v-if="documents.length > 0 && currentType === 'allDocs'"
        >
          <div class="all-documents-container__title">
            <i class="ion ion-documents-outline"></i>
            Documents
          </div>
          <div class="all-documents-container__toolbar">
            <DownloadFilesModal :documents="documents" :isCargoCare="isCargoCare" />
            <ShareFilesModal
              :documents="documents"
              :isCargoCare="isCargoCare"
              :insuranceId="insuranceId"
            />
          </div>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import DocumentDropdown from "./DocumentDropdown.vue";
import onReSize from "../../../../Extend/onResize";
import GetDocumentName from "../../../../Extend/GetDocumentName";
import DownloadFilesModal from "./DownloadFilesModal.vue";
import ShareFilesModal from "./ShareFilesModal.vue";

export default {
  name: "DocumentsList",
  mixins: [onReSize, GetDocumentName],
  props: {
    documents: Array,
    tokenInfo: Object,
    currentType: String,
    isCargoCare: Boolean,
    insuranceId: String,
  },
  components: {
    DocumentDropdown,
    DownloadFilesModal,
    ShareFilesModal,
  },
  methods: {
    getDocumentScr(item) {
      if (this.isCargoCare) {
        this.$emit("getSelectedDocument", item, true);
        this.$emit("disableScroll");
        return;
      }
      this.$emit("getSelectedDocument", item.url, true);
      this.$emit("disableScroll");
    },
  }
};
</script>

<style scoped lang="scss">
.documents-container {
  height: 100%;
}
.documents-list {
  height: 100%;
  &-content {
    height: 100%;
    overflow-y: scroll;
    text-align: start;
    list-style: none;
    margin: 0;
    padding: 15px;
    @media (max-width: 800px) {
      padding: 15px 5px 15px 15px;
    }
  }
}
.documents__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
  button {
    width: 65px;
    border-radius: 15px;
    height: 30px;
    background: $color-primary-company;
    border: none;
    color: $color-white;
    i {
      text-align: center;
    }
    @media (max-width: 500px) {
      margin-bottom: 5px;
    }
  }
  &--link {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-overflow: ellipsis;
    a {
      color: $color-primary-company;
      cursor: pointer;
    }
    i {
      color: #f40f02;
    }
    :hover {
      text-decoration: underline !important;
    }
  }
}
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.all-documents-container {
  width: 100%;
  text-align: start;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &__title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    i {
      font-size: 18px;
    }
  }
  &__toolbar {
    display: flex;
  }
}
</style>
