<template>
  <div class="share-files-form">
    <div class="input-container--email mt-1 mb-1">
      <label class="share-files-form__label"> Emails </label>
      <div
        class="share-files-form__emails"
        :class="emptyFields['emails'] === true && !form.emails.length ? 'missing-field' : ''"
      >
        <Chips
          v-model.trim="form.emails"
          class="input-information-email"
          :placeholder="$t('claimOrDispute.email')"
          :validateEmails="true"
          @input="analyzeValidEmails"
        />
      </div>
      <span class="share-files-form__missing-email" v-if="validEmails">
        {{ $t("myLoads.shareDocument.missingEmail") }}
      </span>
    </div>
    <div class="input-container mt-1 mb-1">
      <label class="share-files-form__label" for="email-message"> Message </label>
      <b-form-textarea
        :class="emptyFields['emailMessage'] === true ? 'missing-field' : ''"
        class="share-files-form__message"
        v-model="form.emailMessage"
        :placeholder="$t('myLoads.shareDocument.messagePlaceholder')"
        rows="3"
        no-resize
        id="email-message"
      ></b-form-textarea>
    </div>
  </div>
</template>

<script>
import ValidateEmail from "@/utils/EmailValidation";
import Chips from "@/components/Chips.vue";

export default {
  name: "ShareFilesForm",
  components: {
    Chips,
  },
  data() {
    return {
      emptyFields: {
        emails: false,
        emailMessage: false,
      },
      form: {
        emails: [],
        emailMessage: "",
      },
      validEmails: false,
    };
  },
  methods: {
    analyzeValidEmails() {
      const emails = this.form.emails.filter((email) => ValidateEmail(email));
      if (emails.length > 0) {
        this.validEmails = false;
      } else {
        this.validEmails = true;
      }
    },
    validateForm() {
      if (!this.form.emails.length || !this.form.emailMessage || this.validEmails) {
        this.emptyFields.emails = this.form.emails.length === 0;
        this.emptyFields.emailMessage = this.form.emailMessage.length === 0;
        return false;
      }
      return this.form;
    },
  },
};
</script>

<style lang="scss" scoped>
.share-files-form {
  font-family: $font-family-portal;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  &__label {
    color: $color-primary-company;
    font-weight: 700;
  }
  .input-container {
    &--email {
      position: relative;
    }
  }
  &__emails {
    @include input-information;
    margin-top: 0;
    border: 1px solid $color-border-container;
    min-height: 40px;
    height: auto;
    width: 100%;
    padding: 0px 0px 0px 5px;
    box-shadow: none;
    border-radius: 10px;
    &:hover {
      box-shadow: none;
    }
    &:focus-within {
      box-shadow: none;
    }
  }
  &__message {
    border: 1px solid $color-border-container;
    background-color: white;
    border-radius: 10px;
    width: 100%;
    box-shadow: none;
    color: black;
    font-weight: normal;
    font-size: 14px;
    &:hover {
      box-shadow: none;
    }
    &:focus-within {
      box-shadow: none;
    }
    &::placeholder {
      color: #b9b9b9;
    }
  }
  &__missing-email {
    font-size: 12px;
    color: red;
    position: absolute;
    bottom: -10px;
    right: 0px;
  }
}

.missing-field {
  background-color: $missing-fields;
}

::v-deep .missing-field .chips-container__input {
  background-color: $missing-fields;
}
</style>
