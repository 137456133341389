<template>
  <div class="document-dropdown" v-click-outside="hide">
    <button class="dropdown-button" @click="showDropdown = !showDropdown">
      <i class="ion ion-chevron-down-outline"></i>
    </button>
    <div class="document-dropdown-box" v-if="showDropdown">
      <ul class="document-dropdown-list">
        <li
          v-for="(option, index) in optionsDropdownButtons"
          :key="index"
          class="document-dropdown-list__item"
          @click="clickDropdown(option.value, document), (showDropdown = false)"
        >
          <button>
            <i :class="option.icon"></i>
          </button>
          <span>{{ option.text }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import downloadDocument from "@/utils/DownloadDocuments";

export default {
  name: "DocumentDropdown",
  props: {
    document: Object,
    tokenInfo: Object,
    isCargoCare: Boolean,
  },
  directives: {
    "click-outside": {
      bind: (el, binding, vnode) => {
        el.clickOutsideEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
      },
      unbind: (el) => {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      },
    },
  },
  data() {
    return {
      showDropdown: false,
      optionsDropdownButtons: [
        {
          value: "OpenExternal",
          text: "Open external",
          icon: "ion ion-open-outline",
        },
        {
          value: "Download",
          text: "Download",
          icon: "ion ion-cloud-download-outline",
        },
      ],
      optionsDropdownButtonsResponsive: [
        {
          value: "Download",
          text: "Download",
          icon: "ion ion-cloud-download-outline",
        },
      ],
    };
  },
  created() {
    if (this.tokenInfo.is_branded === false) {
      const shareDocument = { value: "Share", text: "Share", icon: "ion ion-mail-outline" };
      this.optionsDropdownButtons.push(shareDocument);
      this.optionsDropdownButtonsResponsive.push(shareDocument);
    }
    if (this.isCargoCare) {
      this.optionsDropdownButtons = [
        {
          value: "Download",
          text: "Download",
          icon: "ion ion-cloud-download-outline",
        },
        { value: "Share", text: "Share", icon: "ion ion-mail-outline" },
      ];
    }
  },
  methods: {
    getDocumentScr(document) {
      if (this.isCargoCare) {
        this.$emit("getSelectedDocument", document, true);
      } else {
        this.$emit("getSelectedDocument", document.url, true);
      }
      this.$emit("disableScroll");
    },
    shareDocument(item) {
      this.$emit("shareDocument", item);
    },
    clickDropdown(type, item) {
      switch (type) {
        case "OpenExternal":
          window.open(item.url, "_blank");
          break;
        case "Download":
          downloadDocument(item.url, item.name);
          break;
        case "Share":
          this.shareDocument(item);
          break;
        default:
          break;
      }
    },
    hide() {
      this.showDropdown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-button {
  background: none;
  border: none;
}

.document-dropdown-box {
  position: absolute;
  width: 200px;
  right: 60px;
  padding: 0px 0px;
  border: 1px solid $color-border-container;
  border-radius: 10px;
  background-color: $color-white;
  box-shadow: 1px 2px 5px rgb(69, 69, 69);
  z-index: 1000;
}

.document-dropdown-list {
  width: 100%;
  border: none;
  background-color: transparent;
  padding: 0px;
  &__item {
    padding: 8px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    &:hover {
      background-color: #e9ecef;
      &:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      &:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
    span {
      margin-left: 5px;
      font-size: clamp(0.875rem, 2vw, 1rem);
    }
    button {
      border: none;
      background-color: transparent;
      cursor: unset;
      width: 30px;
    }
    i {
      display: flex;
      justify-content: flex-start;
      color: $color-primary-company;
      max-width: 30px;
      margin: 0;
      font-size: 20px;
    }
  }
}
</style>
