export default {
  methods: {
    getDocumentName(itemUrl) {
      const chosenLoad = this.$store.getters["load/getChosenLoadDetailsView"];
      let docType = "";
      if (itemUrl.includes("/bol/SHP")) {
        docType = "bol";
      } else if (itemUrl.includes("labels/")) {
        docType = "shipping_labels";
      } else if (itemUrl.includes("/WEIGHT_CERTIFICATE/")) {
        docType = "weight_certificate";
      } else if (itemUrl.includes("/portal-tender-documents/")) {
        const formattedDocumentName = this.getTheOriginalDocumentNameAndExtension(itemUrl);
        return `${chosenLoad.id}-${formattedDocumentName}`;
      }
      const fileName = this.getTheOriginalDocumentNameAndExtension(itemUrl);
      return docType ? `${docType}-${fileName}` : fileName;
    },
    getTheOriginalDocumentNameAndExtension(itemUrl) {
      const urlParts = itemUrl.split("/");
      const fileNameWithExtension = decodeURIComponent(urlParts[urlParts.length - 1]);
      const fileName = fileNameWithExtension.split("?")[0];
      return fileName;
    },
  },
};
