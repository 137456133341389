<template>
  <div class="download-files-modal">
    <button class="download-files-modal__button ml-3" @click="showModal()">
      <i class="ion ion-cloud-download-outline"></i>
    </button>
    <CustomModal modalTitle="Download Files" v-model="show" :centered="true" size="md">
      <template #modal-content>
        <DocumentCheckboxesItems
          v-model="documentsToDownload"
          :documents="documents"
          :isCargoCare="isCargoCare"
          @documentsChecked="setChosenDocuments"
        />
        <div class="download-files-toolbar">
          <button
            class="download-files-toolbar__button download-files-toolbar__button--cancel"
            @click="closeModal"
          >
            <span> Cancel </span>
          </button>
          <button
            class="download-files-toolbar__button download-files-toolbar__button--send"
            @click="downloadFiles"
          >
            <span> Download </span>
          </button>
        </div>
      </template>
    </CustomModal>
  </div>
</template>

<script>
import downloadDocument from "@/utils/DownloadDocuments";
import CustomModal from "../../../../components/CustomModal.vue";
import DocumentCheckboxesItems from "./DocumentsCheckboxesItems.vue";

export default {
  name: "DownloadFilesModal",
  props: {
    documents: Array,
    isCargoCare: Boolean
  },
  components: {
    CustomModal,
    DocumentCheckboxesItems,
  },
  data() {
    return {
      show: false,
      documentsToDownload: [],
    };
  },
  methods: {
    setChosenDocuments(documents) {
      this.documentsToDownload = [...documents];
    },
    showModal() {
      this.show = true;
    },
    downloadFiles() {
      if (!this.documentsToDownload.length) {
        this.showAlert({
          title: "Error",
          text: "Please select at least one file to download",
          icon: "warning",
        });
        return;
      }
      for (let i = 0; i < this.documentsToDownload.length; i += 1) {
        downloadDocument(this.documentsToDownload[i].url, this.documentsToDownload[i].name);
      }
      this.showAlert({
        title: "Success",
        text: "Files download in process",
        icon: "success",
      });
      this.show = false;
    },
    showAlert(payload) {
      this.swal({
        title: payload.title,
        text: payload.text,
        icon: payload.icon,
        confirmButtonText: "Ok",
      });
    },
    closeModal() {
      this.show = false;
      this.documentsToDownload = [];
    },
  },
};
</script>

<style lang="scss" scoped>

.download-files-modal {
  &__button {
    width: 65px;
    border-radius: 15px;
    height: 30px;
    background: $color-primary-company;
    border: none;
    color: $color-white;
    i {
      text-align: center;
    }
    @media (max-width: 500px) {
      margin-bottom: 5px;
    }
  }
}

.download-files-toolbar {
  margin-top: 10px;
  display: flex;
  justify-content: space-evenly;
  gap: 5px;
  &__button {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    gap: 10px;
    @extend %flex-center-center;
    padding: 5px 20px;
    width: 40% !important;
    height: 35px !important;
    border-radius: 13px !important;
    @include font-button-text;
    font-weight: 500;
    &--send {
      background-color: $color-save-button;
      color: $color-white;
      border: none;
    }
    &--cancel {
      @include secondary-button();
      width: 30%;
    }
  }
}
</style>
