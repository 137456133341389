<template>
  <div class="documents-checkboxes">
    <div class="document-item" v-for="(document, index) in documents" :key="index">
      <b-form-checkbox
        class="document-item__checkbox"
        :id="document.name"
        @change="checkDocument($event, document)"
      />
      <label v-if="!isCargoCare" class="document-item__name" :for="document.name"
        >{{ formatDocumentName(document) }}
      </label>
      <label v-if="isCargoCare" class="document-item__name" :for="document.name"
        >{{ document.name }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "DocumentsCheckboxesItems",
  props: {
    documents: Array,
    isCargoCare: Boolean,
  },
  data() {
    return {
      filteredDocuments: [],
      documentsChecked: [],
    };
  },
  methods: {
    formatDocumentName(document) {
      const itemUrl = document.url;
      let docType = "";
      if (itemUrl.includes("/bol/")) {
        docType = "Bill of lading";
      } else if (itemUrl.includes("/shipping_labels/") || itemUrl.includes("shiping-labels")) {
        docType = "Labels";
      } else if (itemUrl.includes("WEIGHT_CERTIFICATE")) {
        docType = "Weight Certificate";
      } else if (itemUrl.includes("blob")) {
        docType = this.getOriginalDocumentNameAndExtension(document.name);
      } else if (itemUrl.includes("BILL_OF_LADING")) {
        docType = "Signed BOL";
      } else if (itemUrl.includes("DELIVERY_RECEIPT")) {
        docType = "POD";
      } else {
        docType = document.name;
      }
      return docType;
    },
    getOriginalDocumentNameAndExtension(documentName) {
      const decodedUrl = decodeURIComponent(documentName);
      const urlFragments = decodedUrl.split("/");
      const fileName = urlFragments[urlFragments.length - 1];
      return fileName;
    },
    checkDocument(value, document) {
      if (value) {
        this.documentsChecked.push(document);
      } else {
        this.documentsChecked = this.documentsChecked.filter((item) => document.name !== item.name);
      }
      this.$emit("documentsChecked", this.documentsChecked);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/Checkboxes.scss";

.document-item {
  display: flex;
  margin-bottom: 8px;
  &__checkbox {
    cursor: pointer;
  }
  &__name {
    margin: 0px;
    color: $color-primary-company;
    font-weight: 500;
  }
}
</style>
