<template>
  <div class="share-files-modal">
    <button class="share-files-modal__button ml-3" @click="showModal()">
      <i class="ion ion-mail-outline"></i>
    </button>
    <CustomModal
      modalTitle="Send Files"
      v-model="show"
      :centered="true"
      size="md"
      :showOverlay="sharingFiles"
      :closeOnBackDrop="!sharingFiles"
      :closeOnEsc="!sharingFiles"
    >
      <template #modal-content>
        <ShareFilesForm ref="shareFilesModal" class="mb-3" />
        <DocumentCheckboxesItems
          v-model="documentsToDownload"
          :documents="documents"
          :isCargoCare="isCargoCare"
          @documentsChecked="setChosenDocuments"
        />
        <div class="share-files-toolbar">
          <button
            class="share-files-toolbar__button share-files-toolbar__button--send"
            @click="shareFiles"
          >
            <span> Send Files </span>
          </button>
        </div>
      </template>
    </CustomModal>
  </div>
</template>

<script>
import ShareFilesForm from "./ShareFilesForm.vue";
import CustomModal from "../../../../components/CustomModal.vue";
import DocumentCheckboxesItems from "./DocumentsCheckboxesItems.vue";

export default {
  name: "ShareFilesModal",
  props: {
    documents: Array,
    isCargoCare: Boolean,
    insuranceId: String
  },
  components: {
    CustomModal,
    DocumentCheckboxesItems,
    ShareFilesForm,
  },
  data() {
    return {
      show: false,
      documentsToDownload: [],
      sharingFiles: false,
    };
  },
  methods: {
    setChosenDocuments(documents) {
      this.documentsToDownload = [...documents];
    },
    showModal() {
      this.show = true;
    },
    async shareFiles() {
      const shareFileForm = this.$refs.shareFilesModal.validateForm();
      if (!shareFileForm) {
        return;
      }
      if (!this.documentsToDownload.length) {
        this.showAlert({
          title: "Error",
          text: "Please select at least one file to share",
          icon: "warning",
        });
        return;
      }
      this.sharingFiles = true;
      if (this.isCargoCare) {
        const params = {
          id: this.insuranceId,
          body: {
            email: shareFileForm.emails,
            Query: [...this.documentsToDownload.map((item) => item.name)],
            mail_content: shareFileForm.emailMessage,
          },
        };
        await this.$store.dispatch("insurance/shareInsuranceFiles", params);
      } else {
        const params = {
          id: this.$route.params.loadId,
          body: {
            email: shareFileForm.emails,
            Query: [...this.documentsToDownload.map((item) => item.bucketName)],
            mail_content: shareFileForm.emailMessage,
          },
        };
        await this.$store.dispatch("share/postShareFile", params);
      }
      this.showAlert({
        title: "Success",
        text: "Files shared successfully",
        icon: "success",
      });
      this.sharingFiles = false;
      this.show = false;
    },
    showAlert(payload) {
      this.swal({
        title: payload.title,
        text: payload.text,
        icon: payload.icon,
        confirmButtonText: "Ok",
      });
    },
    closeModal() {
      this.show = false;
      this.documentsToDownload = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.share-files-modal {
  &__button {
    width: 65px;
    border-radius: 15px;
    height: 30px;
    background: $color-primary-company;
    border: none;
    color: $color-white;
    i {
      text-align: center;
    }
    @media (max-width: 500px) {
      margin-bottom: 5px;
    }
  }
}

.share-files-toolbar {
  margin-top: 10px;
  display: flex;
  justify-content: space-evenly;
  gap: 5px;
  &__button {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    gap: 10px;
    @extend %flex-center-center;
    padding: 5px 20px;
    width: 42% !important;
    height: 35px !important;
    border-radius: 13px !important;
    @include font-button-text;
    font-weight: 500;
    &--send {
      background-color: $color-save-button;
      color: $color-white;
      border: none;
    }
  }
}
</style>
